
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home"
import Aboutus from './pages/Aboutus';
import Contectus from './pages/Contectus';
import Service from './pages/Service';
import ServiceDataa from './components/ServiceDataa';

function App() {
  return (
    <div className="App">
     
      <BrowserRouter> 
      
      <Routes>

     <Route path='/' element={<Home/>}></Route>
     <Route path='/top-banquet-vasai' element={<Aboutus/>}></Route>
     <Route path='/banquet-near-vasai' element={<Contectus/>}></Route>
     {
        ServiceDataa.map((route,index)=>{
          return(
            <>
                  <Route
                    key={index}
                    exact
                    path={route.Slugs}
                    element={<Service/>}
                  />
            </>
          )
        })
      }
      </Routes>

    </BrowserRouter>
   
    </div>
  );
}

export default App;
